.swiper-pagination {
  @apply flex gap-s8 items-center;

  .swiper-pagination-bullet {
    @apply w-s8 h-s8 rounded-full bg-dark-grey;
  }

  .swiper-pagination-bullet-active {
    @apply bg-secondary;
  }
}

.swiper-button-disabled {
  @apply border-mid-grey pointer-events-none;

  .gs-i-black::before {
    @apply bg-mid-grey;
  }
}
