.article-content-home,
.article-content-inner,
.article-content-post {
  > :not(section) {
    @apply flex flex-col max-w-[1156px] mx-auto px-s24 lg:px-0;
  }

  > ul,
  > ul ul {
    @apply list-disc pl-s24 text-md;
  }

  > p {
    @apply text-md;
    margin-bottom: 24px !important;

    &:empty {
      @apply hidden;
    }
  }

  h1.wp-block-heading {
    @apply text-h1 text-black font-medium mb-4;
  }

  h2.wp-block-heading {
    @apply text-h2 text-black font-medium mb-4;
  }

  h3.wp-block-heading {
    @apply text-h3 text-black font-medium mb-4;
  }

  h4.wp-block-heading {
    @apply text-h4 text-black font-medium mb-4;
  }

  h5.wp-block-heading {
    @apply text-h5 text-black font-medium mb-4;
  }

  h6.wp-block-heading {
    @apply text-lg text-black font-medium mb-4;
  }

  p {
    @apply mb-[16px] leading-[27px];
  }
}

.article-content-inner {
  &:has(> section:first-child) {
    @apply pt-0;
  }

  &:has(> section:last-child) {
    @apply pb-0;
  }
}
